import React from 'react';
import './css/Navbar.style.css';
import logo from '../../assets/img/LOGO.svg'
import { Navbar, Nav, Container } from 'react-bootstrap';
import $ from 'jquery';

export default function Navegador() {

    var navbarCollapse = function () {
        if ($("#mainNav").offset().top > 100) {
            $("#mainNav").addClass("navbar-scrolled");
        } else {
            $("#mainNav").removeClass("navbar-scrolled");
        }
    };

    function mover(e) {
        var target = e.target.href;
        target = target.split("#");
        $('html, body').animate({
            scrollTop: ($(`#${target[1]}`).offset().top - 55)
        }, 1000);
        $("#Colapsar").removeClass("show");
        $("#btnColapsar").addClass("collapsed");
    }

    $(window).scroll(navbarCollapse);

    $(document).ready(function () {
        navbarCollapse();

        // Collapse the navbar when page is scrolled

    });

    return (

        <Navbar id="mainNav" expand='lg' variant='light' fixed='top'>
            <Container>
           
                <Navbar.Brand href="#page-top">
                    <img
                    alt=""
                    src={logo}
                    width="90"
                    className="d-inline-block "
                />
                </Navbar.Brand>
            
                
                
                <Navbar.Toggle id="btnColapsar" aria-controls="Colapsar" />
                <Navbar.Collapse id="Colapsar">
                    <Nav className="ml-auto">
                        <Nav.Item>
                            <Nav.Link href="#about" onClick={mover}>Empresa</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#services" onClick={mover}>Servicios</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#portfolio" onClick={mover}>Portafolio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#contact" onClick={mover}>Contacto</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link href="https://shop.solucioneskmp.com">Tienda</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}