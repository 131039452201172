import React from 'react';
import './css/portafolio.style.css';
import { ResponsiveEmbed, Modal } from 'react-bootstrap';


export default function Portafolio({ video, show, handleClose }) {
    
    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title >{video.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ResponsiveEmbed>
                    <video src={video.link} type={video.type} autoPlay controls controlsList="nodownload"></video>
                </ResponsiveEmbed>
            </Modal.Body>
            <Modal.Footer>{video.descripcion}</Modal.Footer>
        </Modal>
    )
};

