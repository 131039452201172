import React from 'react';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Contact() {

    return (
       <section className="page-section" id="contact">
           <Container>
        <Row className="justify-content-center">
            <Col lg={8} className="text-center">
                <h2 className="mt-0">¡Sigamos en contacto!</h2>
                <hr className="divider green my-4"/>
                <p className="text-muted mb-5">¿Listo para realizar su proyecto con nosotros? Llámenos o escribanos un correo y nosotros nos contactaremos con usted a la brevedad.</p>
            </Col>
        </Row>
        <Row>
            <Col lg={4} className="mr-auto text-center">
            <FontAwesomeIcon icon={['fas', 'envelope']} width={18} size="4x" className="mb-4" color="#A3A3A3" />
            <NavLink href="mailto:contact@yourwebsite.com">ventas@solucioneskmp.com</NavLink>
            </Col>
            <Col lg={4} className="ml-auto text-center mb-5 mb-lg-0">
            <FontAwesomeIcon icon={['fas', 'phone']} width={18} size="4x" className="mb-4" color="#A3A3A3" />
            <div>+52 (55) 28 99 79 27</div>
            </Col>
        </Row>
           </Container>
       </section>

    )
}