import React from 'react';
//import logo from './logo.svg';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import Navbar from './components/navbar/Navbar';
import Header from './components/header/Header';
import About from './components/contents/about/About';
import Services from './components/contents/services/Services';
import Portafolio from './components/contents/portafolio/Portafolio';
import Contacto from './components/contents/contact/Contact';
import Footer from './components/fotter/Footer';


library.add(fab, far, fas);

function App() {
 
  return (
    <>
    <Navbar />
    <Header />
    <About />
    <Services />
    <Portafolio />
    <Contacto />
    <Footer />
    </>
  );
}

export default App;
