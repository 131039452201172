import React from 'react';
import './css/services.style.css';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
export default function Services() {
    $(".icono").hover(function () {
        alert("hola")
    }, function () {
        alert("hola")
    });
    return (
        <section className='page-section' id="services">
            <Container>
                <h2 className="text-center mt-0">Nuestros Servicios</h2>
                <hr className="divider green my-4"></hr>
                <Row>
                    <Col md={6} lg={4} className="text-center">
                        <div className="mt-5">
                            <NavLink href="#about" style={{ padding: '0', margin: '0' }}><FontAwesomeIcon icon={['fas', 'laptop']} className="mb-4 hovericon icono" width={18} size="4x" color="#00A859" /></NavLink>
                            <h3 className="h4 mb-2">PC's y Laptop's</h3>
                            <p className="text-muted mb-0">Mantenimiento y reparación de equipos de cómputo.</p>
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="text-center">
                        <div className="mt-5">
                            <NavLink href="#about" style={{ padding: '0', margin: '0' }} ><FontAwesomeIcon icon={['fas', 'cog']} width={18} size="4x" className="mb-4 hovericon" color="#00A859" /></NavLink>
                            <h3 className="h4 mb-2">Diseño Mecánico</h3>
                            <p className="text-muted mb-0">Elaboración de planos, cálculos, fabricación, armado...</p>
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="text-center">
                        <div className="mt-5">
                            <NavLink href="#about" style={{ padding: '0', margin: '0' }} ><FontAwesomeIcon icon={['fas', 'shipping-fast']} width={18} size="4x" className="mb-4 hovericon" color="#00A859" /></NavLink>
                            <h3 className="h4 mb-2">Suministro</h3>
                            <p className="text-muted mb-0">Circuitos integrados, equipos de medición, equipos para instrumentación... ¡visita nuestra TIENDA!</p>
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="text-center">
                        <div className="mt-5">
                            <NavLink href="#about" style={{ padding: '0', margin: '0' }} ><FontAwesomeIcon icon={['fas', 'globe']} width={18} size="4x" className="mb-4 hovericon" color="#00A859" /></NavLink>
                            <h3 className="h4 mb-2">Importación</h3>
                            <p className="text-muted mb-0">Contamos con padrón de importadores para productos relacionados con la industria en general, si tienes algún producto que desees importar o estés buscando alguno, ¡pregúntanos!.</p>
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="text-center">
                        <div className="mt-5">
                            <NavLink href="#about" style={{ padding: '0', margin: '0' }} ><FontAwesomeIcon icon={['fas', 'code']} width={18} size="4x" className="mb-4 hovericon" color="#00A859" /></NavLink>
                            <h3 className="h4 mb-2">Diseño de Software</h3>
                            <p className="text-muted mb-0">Programación de PLC's, dispositivos embebidos, diseño web, SCADA etc.</p>
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="text-center">
                        <div className="mt-5">
                            <NavLink href="#about" style={{ padding: '0', margin: '0' }} ><FontAwesomeIcon icon={['fas', 'sim-card']} width={18} size="4x" className="mb-4 hovericon" color="#00A859" /></NavLink>
                            <h3 className="h4 mb-2">Diseño de Hardware</h3>
                            <p className="text-muted mb-0">Desarrollo de circuitos electrónicos, equipos para instrumentación, diseños a la medida...</p>
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="text-center">
                       
                    </Col>
                    <Col md={6} lg={4} className="text-center">
                        <div className="mt-5">
                            <NavLink href="#about" style={{ padding: '0', margin: '0' }} ><FontAwesomeIcon icon={['fas', 'user']} width={18} size="4x" className="mb-4 hovericon" color="#00A859" /></NavLink>
                            <h3 className="h4 mb-2">Soporte Técnico</h3>
                            <p className="text-muted mb-0">Asesoría técnica y reparación de instrumentos electrónicos.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >

    )
}