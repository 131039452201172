import React from 'react';
import { Container } from 'react-bootstrap';


export default function Footer() {

    return (
        <footer className="bg-light py-5">
            <Container fluid="sm" className="text-center text-muted">
                Copyright © 2020 - SolucionesKMP
            </Container>
        </footer>

    )
}