import React from 'react';
import './css/portafolio.style.css';
import { Col, Row, NavLink, Image } from 'react-bootstrap';


export default function Portafolio({ handleShow, Arreglo }) {

    return (
        <Row noGutters>
            {Arreglo.map((txt, index) => (
                <Col key={index} lg={4} sm={6} >
                    <NavLink className="portfolio-box" onClick={handleShow} style={{ margin: '0', padding: '0' }}>
                        <Image fluid src={txt.img} />
                        <div id={index} className="portfolio-box-caption">
                            <div className="project-category text-white-50">{txt.categoria}</div>
                            <div className="project-name">{txt.titulo}</div>
                        </div>
                    </NavLink>
                </Col>
            ))}
        </Row>
    )
}

