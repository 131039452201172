import React, { useState } from 'react';
import './css/portafolio.style.css';
import { Container } from 'react-bootstrap';
import Reproductor from './Reproductor';
import Items from './Items';
//videos e imagenes para galeria.
import Video1 from './videos/video1/video.mp4';
import img1 from './videos/video1/img.jpg';
import Video2 from './videos/video2/video.mp4';
import img2 from './videos/video2/img.jpg';
import Video3 from './videos/video3/video.mp4';
import img3 from './videos/video3/img.jpg';
import Video4 from './videos/video4/video.mp4';
import img4 from './videos/video4/img.jpg';
import Video5 from './videos/video5/video.mp4';
import img5 from './videos/video5/img.jpg';
import Video6 from './videos/video6/video.mp4';
import img6 from './videos/video6/img.jpg';
import Video7 from './videos/video7/video.mp4';
import img7 from './videos/video7/img.jpg';
import Video8 from './videos/video8/video.mp4';
import img8 from './videos/video8/img.jpg';
import Video9 from './videos/video9/video.mp4';
import img9 from './videos/video9/img.jpg';

export default function Portafolio() {

    const arrayvideos = [
        {
            categoria: "Entretenimiento",
            titulo: "Pantalla emergente RF",
            descripcion: "Proyecto realizado para la industria televisiva en la cabina de control, el usuario activa un botón RF y automáticamente bajan o suben las pantallas.",
            img: img1,
            link: Video1,
            type: "video/mp4"
        },
        {
            categoria: "Entretenimiento",
            titulo: "Piso removible RF",
            descripcion: "En este proyecto se diseñó un orificio accionado por pistones neumáticos a radiofrecuencia para provocar la caída de los participantes.",
            img: img2,
            link: Video2,
            type: "video/mp4"
        },
        {
            categoria: "Entretenimiento",
            titulo: "Cortinas automatizadas",
            descripcion: "Cortinas accionadas por servomotor fabricado por Soluciones Electrónicas KMP, el cual sube o baja una cortina con posiciones programadas, obedeciendo rampas de aceleración y desaceleración.",
            img: img3,
            link: Video3,
            type: "video/mp4"
        },
        {
            categoria: "Utilidades",
            titulo: "Escalera retráctil",
            descripcion: "Se diseñó el control de un pistón hidráulico con límites de carrera para poder transformar una escalera en una sección de piso.",
            img: img4,
            link: Video4,
            type: "video/mp4"
        },
        {
            categoria: "Entretenimiento",
            titulo: "Botoneras de concurso",
            descripcion: "Se diseñaron tarjetas electrónicas receptoras en cada uno de los módulos y un transmisor con sus respectivos botones para los módulos de cada pódium, se pueden activar hasta 1.2km de distancia.",
            img: img5,
            link: Video5,
            type: "video/mp4"
        },
        {
            categoria: "Entretenimiento",
            titulo: "Pantallas Móviles",
            descripcion: "Movimiento para 3 pantallas en escenografía 'La Academia'. El sistema esconde y muestra pantallas en diferentes posiciones controladas por servomotor.",
            img: img6,
            link: Video6,
            type: "video/mp4"
        },
        {
            categoria: "Entretenimiento",
            titulo: "Pantallas móviles frontales",
            descripcion: "Movimiento de una pantalla frontal para el paso de participantes por medio de un servomotor controlado por botonera en 3 diferentes posiciones programadas, obedeciendo rampas de aceleración y desaceleración en el proyecto 'Pequeños Gigantes'",
            img: img7,
            link: Video7,
            type: "video/mp4"
        },
        {
            categoria: "Entretenimiento",
            titulo: "Luces de corrimiento programadas",
            descripcion: "Consiste en un programa que activa diferentes conjuntos de  luces individualmente generando diferentes efectos, el sistema es programable y no tiene límite de luces a controlar.",
            img: img8,
            link: Video8,
            type: "video/mp4"
        },
        {
            categoria: "Entretenimiento",
            titulo: "Pódiums Neumáticos",
            descripcion: "Pódiums Neumáticos Sistema neumático automatizado para el accionamiento de diferentes pódium individuales, 12 chicos y 2 grandes.",
            img: img9,
            link: Video9,
            type: "video/mp4"
        }

    ];

    const [show, setShow] = useState(false);
    const [video, setVideo] = useState({
        id: '0',
        titulo: "",
        descripcion: "",
        link: "",
        type: ""
    });
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setVideo({
            ...video,
            "id": e.target.id,
            "titulo": arrayvideos[e.target.id].titulo,
            "descripcion": arrayvideos[e.target.id].descripcion,
            "link": arrayvideos[e.target.id].link,
            "type": arrayvideos[e.target.id].type
        });
        setShow(true);
    }
    return (
        <>
            <div id="portfolio">
                <Reproductor video={video} show={show} handleClose={handleClose} />

                <Container fluid style={{ margin: '0', padding: '0' }}>

                    <Items handleShow={handleShow} Arreglo={arrayvideos} />

                </Container>
            </div>
        </>


    )
}