import React from 'react';
import './css/about.style.css';
import { Container, Row, Col } from 'react-bootstrap';

export default function Header() {

    return (
        <section className='page-section bg-primary' id="about">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8} className="text-center">
                        <h2 className="text-white mt-0">¡Tenemos todo lo que necesitas!</h2>
                        <hr className="divider light my-4"></hr>
                        <p className="text-white-50 mb-4">
                        Desde el año 2015 nos hemos dedicado a generar nuevas tecnologías de calidad Mexicana de la industria en general, sabemos identificar con exactitud las necesidad que se presentan en la industria y somos capaces de ofrecer una solución integra y multidisciplinaria con nuestro amplio campo de aplicación.
                             </p>
                             <p className="text-white-50 mb-4">
                             Hemos incursionado en diferentes sectores como lo ha sido la industria del Entretenimiento, Televisoras, Proyectos varios de automatización para industrias alimenticias, médica logística y también distribuyendo servicios administrativos vía WEB.
                             </p>
                    </Col>
                </Row>
            </Container>
        </section >

    )
}