import React from 'react';
import './css/header.style.css';
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery';

export default function Header() {
    function mover(e) {
        var target = e.target.href;
        target = target.split("#");
        $('html, body').animate({
            scrollTop: ($(`#${target[1]}`).offset().top - 55)
        }, 1000);
        $("#Colapsar").removeClass("show");
        $("#btnColapsar").addClass("collapsed");
    }
    return (
        <header className='masthead'>
            <Container className="h-100">
                <Row className="h-100 align-items-center justify-content-center text-center">
                    <Col lg={10}>
                        <h1 className="text-uppercase text-white font-weight-bold">Automatización y control en su máxima expresión</h1>
                        <hr className="divider my-4 green" />
                    </Col>
                    <Col lg={8} className="align-self-baseline">
                        <p className="text-white-75 font-weight-light mb-5">Empresa especializada en desarrollo de hardware, software, sistemas de control, automatización, diseño web, diseño electrónico y más para la industria.</p>
                        <a className="btn btn-primary btn-xl" onClick={mover} href="#about">Saber más...</a>
                    </Col>
                </Row>
            </Container>
        </header >

    )
}